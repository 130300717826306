<script setup>
import { Head, useForm } from '@inertiajs/vue3'
import AuthLayout from '@/App/Layouts/AuthLayout.vue'
import AppLink from '@/Shared/Components/Links/AppLink.vue'
import Checkbox from '@/Shared/Components/Forms/Checkbox.vue'
import FormTitle from '@/Shared/Components/Sections/FormTitle.vue'
import InputError from '@/Shared/Components/Forms/InputError.vue'
import InputLabel from '@/Shared/Components/Forms/InputLabel.vue'
import PrimaryButton from '@/Shared/Components/Buttons/PrimaryButton.vue'
import TextInput from '@/Shared/Components/Forms/TextInput.vue'
import SocialMediaLogin from '../../../Shared/Components/Sections/SocialMediaLogin.vue'
import ErrorComponent from '@/Shared/Components/Sections/ErrorComponent.vue'
import SectionSecondaryTitle from '@/Shared/Components/Sections/SectionSecondaryTitle.vue'

defineProps({
  canResetPassword: Boolean,
  status: String,
})

const form = useForm({
  email: '',
  password: '',
  remember: false,
})

const submit = () => {
  form
    .transform((data) => ({
      ...data,
      remember: form.remember ? 'on' : '',
    }))
    .post(route('login'), {
      onFinish: () => form.reset('password'),
    })
}
</script>

<template>
  <Head title="Sign in" />

  <AuthLayout>
    <FormTitle>Sign in to your TravelEssex transport provider account</FormTitle>
    <ErrorComponent
      :errors="form.errors"
      class="mb-6 mt-1"
    />

    <div
      v-if="status"
      class="mb-4 font-medium text-sm text-green-600"
    >
      {{ status }}
    </div>

    <div class="sm:w-4/6">
      <form @submit.prevent="submit">
        <div :class="form.errors.email ? 'border-l-4 pl-2 border-red-600 ' : undefined">
          <InputLabel
            for="email"
            value="Email address"
          />
          <TextInput
            id="email"
            v-model="form.email"
            type="email"
            class="mt-1 block w-full"
            required
            autofocus
          />
          <InputError
            class="mt-2"
            :message="form.errors.email"
          />
        </div>

        <div class="mt-4">
          <InputLabel
            for="password"
            value="Password"
          />
          <TextInput
            id="password"
            v-model="form.password"
            type="password"
            class="mt-1 block w-full"
            required
            autocomplete="current-password"
          />
          <InputError
            class="mt-2"
            :message="form.errors.password"
          />
        </div>

        <div class="flex items-center justify-between mt-6">
          <div class="block">
            <label class="flex items-center">
              <Checkbox
                v-model:checked="form.remember"
                name="remember"
              />
              <span class="ml-2 text-black">Remember me</span>
            </label>
          </div>
          <AppLink
            v-if="canResetPassword"
            :href="route('password.request')"
            inertia
          >
            Forgot your password?
          </AppLink>
        </div>

        <div class="flex items-center mt-6">
          <PrimaryButton
            :class="{ 'opacity-25': form.processing }"
            :disabled="form.processing"
          >
            Sign in
          </PrimaryButton>
        </div>
      </form>
      <SocialMediaLogin :text="'Sign in with your social account'" />
      <SectionSecondaryTitle class="mt-3">New users of TravelEssex</SectionSecondaryTitle>
      <AppLink
        inertia
        :href="route('tp.register.welcome')"
      >
        Create a new transport provider account
      </AppLink>
    </div>
  </AuthLayout>
</template>
